import { useMemo, useCallback, createContext, useEffect, useRef } from "react";
import useSupabase from "./useSupabase";
import { SupabaseClient, User as SupabaseUser } from "@supabase/supabase-js";
import { Tables } from "../../types/database";
import useTable from "./useTable";
import { debugLog } from "../../lib/debug";

export type User = Tables<"users">;

type UserContextType<User> = {
  user: User;
  isLoading: boolean;
  updateUser: (User: User) => Promise<User[]>;
  deleteUser: (User: User) => Promise<User[]>;
  insertUser: (User: User) => Promise<User[]>;
  recordTip: (amount: number) => Promise<User[] | null>;
};

export const UserContext = createContext<UserContextType<User>>({
  user: null,
  isLoading: false,
  updateUser: null,
  deleteUser: null,
  insertUser: null,
  recordTip: null,
});

export function useUsers({
  sessionUser,
  visible = true,
}: {
  sessionUser?: SupabaseUser;
  visible?: boolean;
}): UserContextType<User> {
  const client = useSupabase();
  const key = useMemo(
    () => ["users", sessionUser?.email ?? ""],
    [sessionUser?.email]
  );
  const idAltPredicate = useCallback(
    (user: User) =>
      JSON.stringify(
        ["email"].map(
          (key: keyof User): string | number | boolean => user?.[key]
        )
      ),
    []
  );
  const {
    rows: users,
    isLoading,
    isFetched,
    updateRow: updateUser,
    insertRows: insertUser,
    deleteRows: deleteUser,
  } = useTable<User>({
    table: "users",
    visible,
    key,
    filter: `email=eq.${sessionUser?.email}`,
    idAltPredicate,
    selectFunction: async (): Promise<User[]> =>
      await selectFunction({ client }),
    updateFunction: async (user: User): Promise<User[]> =>
      await updateFunction({ client, user }),
    insertFunction: async (user: User[]): Promise<User[]> =>
      await insertFunction({ client, users: user }),
    deleteFunction: async (user: User): Promise<User[]> =>
      await deleteFunction({ client, user }),
  });
  const hasRunOnce = useRef<boolean>(false);
  const user = useMemo((): User => users?.[0] ?? null, [users]);

  // create a user record if the user has authenticated but no user record
  // was found when searching on `email`
  useEffect(() => {
    // if hook was provided `email`, first fetch ran, and hasRunOnce is still false...
    if (sessionUser?.email && isFetched && !hasRunOnce.current) {
      /// if `user` (table query) is null, create a user record
      if (user === null) {
        debugLog(
          `No user record found for ${sessionUser.email}... creating user record.`
        );
        insertUser([
          {
            email: sessionUser.email,
            name: userDisplayName(sessionUser),
            opt_in: true,
            tip_last_amount: 0,
            tip_total: 0,
          },
        ]);
      }
      // if `user` (table query) is null, create a user record
      else {
        debugLog(
          `Updating the last_seen for user record of ${sessionUser.email}...`
        );
        updateUser({
          ...user,
          last_seen: new Date().toISOString(),
        });
      }

      hasRunOnce.current = true;
    }
  }, [sessionUser, isFetched, user, insertUser, updateUser]);

  const recordTip = useCallback(
    async (amount: number): Promise<User[] | null> =>
      user &&
      (await updateUser({
        ...user,
        tip_last_amount: amount,
        tip_last_date: new Date().toISOString(),
        tip_total: (user?.tip_total ?? 0) + amount,
      })),
    [user, updateUser]
  );

  return {
    user,
    isLoading,
    updateUser,
    insertUser,
    deleteUser,
    recordTip,
  };
}

// lean on the users SELECT policy to only return the authenticated user
const selectFunction = async ({
  client,
}: {
  client: SupabaseClient;
}): Promise<User[]> =>
  client
    .from("users")
    .select()
    .throwOnError()
    .then((result): User[] => result.data);

export const updateFunction = async ({
  client,
  user,
}: {
  client: SupabaseClient;
  user: User;
}): Promise<User[]> =>
  client
    .from("users")
    .update(user)
    .eq("id", user.id)
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): User[] => result.data);

export const insertFunction = async ({
  client,
  users,
}: {
  client: SupabaseClient;
  users: User[];
}): Promise<User[]> =>
  client
    .from("users")
    .insert(users)
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): User[] => result.data);

// this operation is not-allowed at the database policy level
export const deleteFunction = async ({
  client,
  user,
}: {
  client: SupabaseClient;
  user: User;
}): Promise<User[]> =>
  client
    .from("users")
    .delete()
    .eq("id", user.id)
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): User[] => result.data);

const userDisplayName = (sessionUser: SupabaseUser) => {
  const userMetadata = sessionUser?.user_metadata ?? {};
  // 1. first name from `name` in `user_metadata`
  const firstName = userMetadata?.name?.split(" ")[0];
  // 2. `user_name` from `user_metadata` (if available)
  const userName = userMetadata?.user_name;
  // 3. user name from `email`
  const emailPrefix = sessionUser?.email?.split("@")[0];
  return firstName || userName || emailPrefix || "Fuego Stats User";
};
