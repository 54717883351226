import { IconFuegoStats } from "./icons";
import packageJson from "../package.json";
import React from "react";

const Footer = () => (
  <div
    className="mb-3 mt-auto pt-2 d-flex justify-content-center align-items-center text-muted"
    style={{ fontSize: "80%" }}>
    <IconFuegoStats className="me-1" size={24} simple />
    Fuego Stats {packageJson.version} is Randy, Adam, Kelly &amp; Will
  </div>
);

export default Footer;
